"use client";

import React from "react";
import Link from 'next/link';

const CallToAction: React.FC = () => {
  return (
    <section
      role="region"
      aria-labelledby="cta-heading"
      className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-20 lg:py-24 bg-white rounded-2xl lg:rounded-3xl shadow-xl overflow-hidden"
    >
      {/* Background subtle gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-emerald-50/40 to-white/50" aria-hidden="true" />

      <div className="relative space-y-8 md:space-y-10 text-center">
        {/* Heading with improved hierarchy */}
        <div className="max-w-4xl mx-auto">
          <h1
            id="cta-heading"
            className="text-3xl md:text-4xl lg:text-5xl font-bold text-slate-900 leading-tight tracking-normal"
          >
            Expert Psychological Care in Calgary
          </h1>
          <p className="mt-4 md:mt-6 text-lg md:text-xl lg:text-2xl text-emerald-800 font-medium">
            Specialized Counseling Services for Individuals, Couples & Families
          </p>
        </div>

        {/* Price with refined presentation */}
        <div className="inline-flex items-center bg-emerald-100/80 px-6 py-3 rounded-full backdrop-blur-sm border border-emerald-200/50">
          <span className="text-xl font-semibold text-emerald-800">
            <span className="text-emerald-700">$160</span>
            <span className="text-xl font-normal text-emerald-700/90 ml-1">per session</span>
          </span>
        </div>

        {/* Services listing with grid layout */}
        <div className="max-w-5xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-4 px-4">
          {['Relationship Counseling', 'Family Therapy', 'Marriage Therapy', 'Individual Support'].map((service) => (
            <div key={service} className="p-3 bg-white rounded-lg shadow-sm border border-slate-100/80">
              <span className="text-base font-medium text-slate-800">{service}</span>
            </div>
          ))}
        </div>

        {/* Primary CTA */}
        <div className="mt-8 flex flex-col items-center space-y-6">
          <Link
            href="tel:+14032440244"
            className="inline-flex items-center px-8 py-4 md:px-10 md:py-5 bg-emerald-700 hover:bg-emerald-600 transition-colors duration-200 rounded-xl md:rounded-2xl shadow-lg hover:shadow-md"
            aria-label="Call Alberta Counselling Services at (403) 244-0244"
          >
            <span className="text-white text-2xl md:text-xl font-semibold tracking-tight">
              <span className="mr-3">📞</span>
              (403) 244-0244
            </span>
          </Link>
        </div>

        {/* Trust elements */}
        <div className="mt-10 space-y-2">
          <div className="flex flex-wrap justify-center gap-4 text-slate-600 text-sm">
            <div className="flex items-center">
              <span className="text-emerald-600 mr-2">✓</span>
              Registered Psychologist
            </div>
            <div className="flex items-center">
              <span className="text-emerald-600 mr-2">✓</span>
              35+ Years Experience
            </div>
            <div className="flex items-center">
              <span className="text-emerald-600 mr-2">✓</span>
              Confidential Services
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
